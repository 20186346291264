import React from 'react'

const MercadoOrganizadoValores = () => {
  return (
    <section className='pt-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col'>
            <h2 className='fs-22 text-orange--extra mb-4'>O que é o mercado organizado de valores mobiliários?</h2>
            <p className='fs-16 text-grayscale--500'>
              Nos termos da Resolução CVM 135, considera-se mercado organizado de valores mobiliários o
              espaço físico ou o sistema eletrônico, destinado à negociação ou ao registro de operações
              com valores mobiliários por um conjunto determinado de pessoas autorizadas a operar, que
              atuam por conta própria ou de terceiros.
            </p>
            <h2 className='fs-22 text-orange--extra mb-4'>O mercado organizado de valores mobiliários se divide entre bolsa e balcão.</h2>
            <h2 className='fs-22 text-orange--extra mb-4 mt-5'>O que seria a Bolsa de Valores? </h2>
            <p className='fs-16 text-grayscale--500'>
              A Bolsa de Valores, ou apenas bolsa, é um ambiente de negociação de valores mobiliários mais
              rígido e fiscalizado dentro do mercado organizado, seja quanto as operações realizadas, seja
              quanto aos valores ali operados.
            </p>
            <p className='fs-16 text-grayscale--500'>
              A bolsa realiza negociações de ativos de empresas de capital aberto e as negociações giram em torno
              de ações, além outros investimentos, como dólar, juros futuros, entre outros. A bolsa de valores brasileira,
              conhecida como <span className='fw-600'> B3</span>, é supervisionada pela CVM.
            </p>
            <p className='fs-16 text-grayscale--500'>
              As negociações ocorrem de forma digital e organizada e os ativos (papéis) são disponibilizados pelas
              corretoras em um painel chamado home broker, no qual é possível ao investidor realizar ordens de compra
              e venda.
            </p>
            <p className='fs-16 text-grayscale--500'>
              Todas as informações das operações realizadas na bolsa são públicas, de modo que o mercado
              possui acesso aos preços, quantidades e horários dos ativos que foram negociados.
            </p>
            <h2 className='fs-22 text-orange--extra mb-4 mt-5'>Como funciona o mercado de balcão organizado?</h2>
            <p className='fs-16 text-grayscale--500'>
              O <span className='fw-600'> mercado de balcão organizado</span>, também chamado de over the counter (OTC), é aquele no qual as
              operações que não têm registro na bolsa de valores, ainda que realizadas através do sistema da B3
              e devidamente controlado pela CMV. Para operar no mercado de balcão é preciso ser uma corretora,
              gestora ou banco de investimento, que conectam as pessoas que desejam operar.
            </p>
            <p className='fs-16 text-grayscale--500'>
              Através do mercado de balcão é possível negociar somente entre duas partes e com menos burocracia, de modo que
              o mercado não possui acesso as características do negócio realizado. Ainda, é possível negociar ativos de
              empresas que não estão na bolsa.
            </p>
            <p className='fs-16 text-grayscale--500'>
              Apesar de ser fiscalizado pela CVM, o mercado de balcão organizado é mais flexível do que a bolsa, tanto para as
              empresas que negociam seus ativos, quanto para as operações realizadas. A título de exemplo, as operações são
              registradas, tal qual na bolsa, contudo, não há necessidade de que as informações sejam prestadas continuamente, admitindo-se a notificação ao final do pregão.
            </p>
            <p className='fs-16 text-grayscale--500'>
              É no mercado de balcão que empresas que não operam via bolsa conseguem se financiar com a venda de participações acionárias ou de títulos de dívida.
              Os investidores, por sua vez, buscam ativos com maior rentabilidade, esperando um retorno maior proveniente do risco.
            </p>
            <p className='fs-16 text-grayscale--500 mb-5'>
              Em resumo: os investidores encontram oportunidades com maior potencial de lucro do que na bolsa e, ao mesmo tempo, as empresas
              conseguem capital para crescer suas operações.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MercadoOrganizadoValores
