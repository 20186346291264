import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
import { Hero } from './sections/Hero/_index'
import MercadoOrganizadoValores from './sections/mercado-organizado-valores/_index'

import { Wrapper } from './style'

const TiposMercadosOrganizados = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <MercadoOrganizadoValores />
      </Layout>
    </Wrapper>
  )
}

export default TiposMercadosOrganizados
